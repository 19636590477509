import React from 'react'
import QualityofHire from '../components/recruitment_blogs/blogs/quality_of_hire/quality_of_hire'
import SEO from '../components/seo'

const Page = (location) => {
  return (
    <React.Fragment>
        <SEO title="Everything You Need to Know to Hire Quality Candidates"
          description="A complete guide to ensure you’re making the right hiring decisions with top quality candidates. How to measure quality of hire? How to improve quality of hire?"
          url="/quality-of-hire/"
          image="https://blogs.mycareerdreams.com/blogs/quality_main.webp"
          type="article"
        />
     <QualityofHire location={location}/>
    </React.Fragment>
  )
}
export default Page;